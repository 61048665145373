@use "../../global" as global;

.p-header-upper {

  &__container {
    display: flex;
    height: global.$height-header-upper;
  }
}

.p-header-logo {
  display: flex;
  align-items: center;
  height: global.$height-header-upper;
  margin-right: auto;
  text-decoration: none;

  @include global.media(sp) {
    margin-right: auto;
  }

  &__link {
    display: flex;
    font-family: global.$font-base;
    font-size: global.rem(24);
    font-weight: 600;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: global.$color-black;
    }
  }

  &__heading {
    @include global.font-settings(22, 30, 1.5, 700);

    @include global.media(sp) {
      line-height: 1.2;
    }
  }

  &__img {
    height: auto;
    object-fit: contain;
    margin-right: 1rem;
  }
}

.p-header {

  &__link-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 45%;
    height: inherit;
    margin-left: auto;
    text-decoration: none;

    @include global.media(sp) {
      display: none;
    }
  }

  &__link {
    width: calc(100% / 6);
    transition: all 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  &__link-figure {
    padding: 5px;
  }

  &__link-img {
    height: global.$height-header-upper;
    object-fit: contain;
  }
}

@include global.media(sp) {

  .p-header-logo {
  }
}

.p-header-nav {
  display: flex;
  width: 100%;

  &__list {
    display: flex;
    width: 100%;
  }

  &__item {
    width: calc(calc(100% - 81px) / 4);
    border-right: 1px solid global.$color-gray;
    border-left: 1px solid global.$color-gray;

    :hover {
      opacity: 0.8;
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    width: 100%;
    font-size: global.rem(17);
    font-weight: 400;
    line-height: global.$height-header-under;
    text-align: center;
    text-decoration: none;
    transition: 0.2s;
  }

  &__contact {
    width: 81px;

    & > a {
      display: block;
      width: 100%;
      height: 100%;
      background-color: global.$color-main;
      background-image: url("../images/icon-contact.png");
      background-repeat: no-repeat;
      background-position: center 20%;
      background-size: 40px;

      &::after {
        position: absolute;
        bottom: 25px;
        left: 50%;
        width: 100%;
        height: 30px;
        font-size: global.rem(12);
        color: global.$color-white;
        content: "お問い合わせ";
        transform: translateX(-50%);
      }
    }
  }
}

@include global.media(sp) {

  .p-header-nav {
    flex-direction: column;

    &__item {

      &:last-child {
        margin-right: 0;
      }
    }

    &__list {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .p-header-nav {
    display: none;
  }
}
