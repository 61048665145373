@use "../../global" as global;

.p-footer-nav {
  @include global.font-settings(15, 15, 1.5, 400);

  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 3rem 0;

  @include global.media(sp) {
    flex-direction: column;
  }

  &__list {
    width: 250px;
  }

  &__item {
    line-height: 1.8;

    @include global.media(sp) {
      margin-bottom: 0.8rem;
    }
  }

  &__link {
    transition: 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
}
