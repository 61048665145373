@use "../global" as global;

html {
  font-size: global.$baseFontSize + px;
}

body {
  min-width: 350px;
  font-family: global.$font-base;
  color: global.$color-black;
  background-color: global.$color-white;
  text-size-adjust: 100%;
}

img {
  max-width: 100%;
}
