@use "../../global" as global;

.c-breadcrumb {
  display: flex;
  gap: 5px;

  // &__wrapper {
  //   margin-bottom: 3rem;
  // }
}

.c-breadcrumb__item {
  @include global.font-settings(12, 12, 1.5 , 400);

  &::after {
    margin: 0 5px;
    content: ">";
  }

  &:last-child {

    &::after {
      display: none;
    }
  }
}
