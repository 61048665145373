@use "../global" as global;

.l-footer {
  color: global.$color-white;
  background-color: global.$color-main;

  &__inner {
    @include global.inner(global.$width-base);

    padding: 0 20px;
  }
}
