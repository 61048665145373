@use "../global" as global;

.l-header-upper {
  width: 100%;
  background-color: global.$color-white;

  @include global.z-index(header);

  &__inner {
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1120px;
    padding: 0 20px;
    margin: 0 auto;

    @include global.media(sp) {
      position: fixed;
      flex-direction: row;
      background-color: global.$color-white;
    }
  }
}

.l-header-under {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: global.$color-white;
  border-top: 2px solid global.$color-gray;
  border-bottom: 2px solid global.$color-gray;

  @include global.z-index(header);

  &__inner {
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    margin: 0 auto;
  }
}

@include global.media(sp) {

  .l-header {
    height: 150px;

    &__inner {
      flex-direction: column;
    }
  }
}
