@use "../../global" as global;

/* ----------------------------
* SPナビゲーションボタン開閉ボタン
*---------------------------- */

.p-header-sp-nav__btn {
  position: relative;
  top: 0;
  right: 0;
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: global.$header-height-pc;
  color: global.$color-main;
}

@include global.media(sp) {

  .p-header-sp-nav__btn {
    display: flex;
    margin-left: auto;
  }
}

@include global.media(sp) {

  .p-header-sp-nav__btn {
    height: global.$header-height-sp;
  }
}

/* ----------------------------
* ボタンアニメーション部分
*---------------------------- */

.p-header-sp-nav__btn .p-header-sp-nav__bar-frame {
  position: relative;
  display: block;
  width: 32.5px;
  height: 2px;
}

.p-header-sp-nav__btn .p-header-sp-nav__first-bar {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: global.$color-main;
  transition: all 0.15s linear;
}

.p-header-sp-nav__btn .p-header-sp-nav__second-bar {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: global.$color-main;
  transition: all 0.15s linear;
  transform: translateY(-8px) rotate(0deg);
}

.p-header-sp-nav__btn .p-header-sp-nav__third-bar {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: global.$color-main;
  transition: all 0.15s linear;
  transform: translateY(8px) rotate(0deg);
}

.p-header-sp-nav__btn.is-active .p-header-sp-nav__first-bar {
  opacity: 0;
}

.p-header-sp-nav__btn.is-active .p-header-sp-nav__second-bar {
  width: 100%;
  transform: translateY(0) rotate(35deg);
}

.p-header-sp-nav__btn.is-active .p-header-sp-nav__third-bar {
  width: 100%;
  transform: translateY(0) rotate(-35deg);
}

/* ----------------------------
* SPナビゲーションメニュー表示
*---------------------------- */

/* アニメーション前のメニューの状態 */

.p-header-sp-nav {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
}

/* アニメーション後のメニューの状態 */

.p-header-sp-nav.is-active {
  z-index: 3;
  visibility: visible;
  opacity: 1;
}

/* ----------------------------
* SPナビゲーションメニューエリア
*---------------------------- */

.p-header-sp-nav {
  position: fixed;
  top: global.$header-height-pc;
  right: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: global.$width-min;
  height: calc(100vh - global.$header-height-pc);
  height: calc(100dvh - global.$header-height-pc);
  background: global.$color-white;
  transform: translateZ(1px);

  @include global.media(sp) {
    top: global.$header-height-sp;
    height: calc(100vh - global.$header-height-sp);
    height: calc(100dvh - global.$header-height-sp);
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px 15px 15px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 0.5rem;

    @include global.font-settings(24, 24, 1.5, 400);
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.p-header-sp-nav {

  &__link-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 0.5rem;
    text-decoration: none;
  }

  &__icon-link {
    width: calc(100% / 3);
    transition: all 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  &__icon-link-figure {
    padding: 5px 15px;
  }

  &__icon-link-img {
    height: global.$height-header-upper;
    object-fit: contain;
  }
}

a#js-scrollTopBtn {
  position: fixed;
  right: max(calc(50% - 650px), 40px);
  bottom: 40px;
  width: 50px;
  height: 50px;
  visibility: hidden;
  background-color: global.$color-main;
  box-shadow: 0 0 10px rgba(0, 0, 0, 30%);
  opacity: 0;
  transition: all 0.5s ease;

  @include global.media(sp) {
    right: 15px;
    bottom: 15px;
  }
}

a#js-scrollTopBtn {
  // z-index: 1;
}

a#js-scrollTopBtn.is-show {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease;
}

a#js-scrollTopBtn:hover {
  opacity: 0.8;
}

a#js-scrollTopBtn::before {
  position: absolute;
  top: 7px;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  margin: auto;
  content: "";
  border-top: 1px solid global.$color-white;
  border-right: 1px solid global.$color-white;
  transform: rotate(-45deg);
}
