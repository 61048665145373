@use "../global" as global;

// アンカーリンクの位置調整

section#company::before,
section#recruit::before {
  display: block;
  height: 60px; /* 調整したい高さ（固定ヘッダーの高さ） */
  margin-top: -60px; /* heightと同じ分のネガティブマージン */
  visibility: hidden;
  content: "";
}

section#group::before {
  display: block;
  height: 120px; /* 調整したい高さ（固定ヘッダーの高さ） */
  margin-top: -120px; /* heightと同じ分のネガティブマージン */
  visibility: hidden;
  content: "";
}

section#press-release::before {
  display: block;
  height: 60px; /* 調整したい高さ（固定ヘッダーの高さ） */
  margin-top: -60px; /* heightと同じ分のネガティブマージン */
  visibility: hidden;
  content: "";
}

.l-banner {

  &__inner {
    @include global.inner(global.$width-inner);

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 60px 20px 30px;
  }
}

@include global.media(sp) {
  // .l-banner__inner {
  //   padding-top: global.$header-height-sp;
  // }
}

.l-press-release {
  padding-bottom: 60px;

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 30px 20px 120px;
  }
}

@include global.media(sp) {

  .l-press-release {

    &__inner {
      @include global.inner(global.$width-inner);

      height: 100%;
    }
  }
}

.l-group {
  background-color: global.$color-bg;

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 0 20px 60px;
  }
}

@include global.media(sp) {

  .l-group {

    &__inner {
      @include global.inner(global.$width-inner);

      height: 100%;
    }
  }
}

.l-company {

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 40px 20px;
  }
}

@include global.media(sp) {

  .l-company {

    &__inner {
      @include global.inner(global.$width-inner);

      height: 100%;
    }
  }
}

.l-recruit {

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 40px 20px;
  }
}

@include global.media(sp) {

  .l-recruit {

    &__inner {
      @include global.inner(global.$width-inner);

      height: 100%;
    }
  }
}

.l-contact {
  // background-color: global.$color-bg;

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 60px 20px;
  }
}

@include global.media(sp) {

  .contact {

    &__inner {
      padding-top: 190px;
    }
  }
}

.l-financial {

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 60px 20px;
  }
}

@include global.media(sp) {

  .financial {

    &__inner {
      padding-top: 190px;
    }
  }
}

.l-page {

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 30px 20px;
  }
}

@include global.media(sp) {

  .l-page {

    &__inner {
      // padding-top: 190px;
    }
  }
}

.l-sitemap {

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 30px 20px;
  }
}

@include global.media(sp) {

  .l-sitemap {

    &__inner {
      // padding-top: 190px;
    }
  }
}

.l-breadcrumb {

  &__inner {
    @include global.inner(global.$width-inner);

    height: 100%;
    padding: 1.5rem 20px;
  }
}

@include global.media(sp) {

  .l-breadcrumb {

    &__inner {
      padding-top: 90px;
    }
  }
}

.l-404 {
  height: calc(100vh - 74px - 78px - 285px);

  &__inner {
    @include global.inner(global.$width-inner);

    padding: 60px 20px;
  }
}

@include global.media(sp) {

  .l-404 {
    height: auto;

    &__inner {
      padding-top: 190px;
    }
  }
}
